import * as React from 'react';
import {
  Box, Center, Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import { graphql } from 'gatsby';
import AppTemplate from '../components/AppTemplate';

// markup
function Privacy({ data }: any) {
  return (
    <AppTemplate
      navigation={data.contentfulNavigation}
      SEO={{
        title: 'Ritta - Tietosuojaseloste',
        description: 'Rittan tietosuojaseloste ja Pulpetin tietokäsittelyseloste',
        author: 'Ritta.fi',
        pathname: '/',
      }}
    >
      <div style={{ minHeight: '100vh' }}>
        <Box
          px={8}
          pt={24}
          mx="auto"
        >
          <Box
            w={{
              base: 'full',
              md: 11 / 12,
              xl: 9 / 12,
            }}
            mx="auto"
            textAlign="center"
          >
            <Heading
              as="h1"
              mb={6}
              fontSize={{
                base: '4xl',
                md: '6xl',
              }}
              fontWeight="bold"
              lineHeight="none"
              letterSpacing={{
                base: 'normal',
                md: 'tight',
              }}
              color="gray.900"
              _dark={{
                color: 'gray.100',
              }}
            >
              Tietosuojaseloste
            </Heading>
          </Box>
        </Box>
        <Box>
          <Flex justifyContent="center" pt={5} pb={20}>
            <Text width={{
              base: '90vw',
              md: '80vw',
              lg: '70vw',
              xl: '40vw',
            }}
            >
              Tämä on Ritta.fi:n EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu 16.1.2023. Viimeisin muutos 16.1.2023.
              <br />
              <br />
              1. Rekisterinpitäjä
              <br />
              Ritta.fi
              <br />
              Y-tunnus: 3235836-5
              <br />
              <br />
              2. Rekisteristä vastaava yhteyshenkilö
              <br />
              Roni Äikäs
              <br />
              Sähköposti: roni@ritta.fi
              <br />
              Osoite: Kalliokatu 4, 42100 JÄMSÄ
              <br />
              Puhelinnumero: 040 219 0030
              <br />
              <br />
              3. Rekisterin nimi
              <br />
              Yrityksen yhteydenottorekisteri
              <br />
              <br />
              4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus
              <br />
              EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on:
              <br />
              <br />
              suostumus (yhteydenoton välitys)
              <br />
              <br />
              Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.
              <br />
              <br />
              5. Rekisterin tietosisältö
              <br />
              Rekisteriin tallennettavia henkilötietoja ovat:
              <br />
              <br />
              nimi
              <br />
              kunta/organisaatio
              <br />
              sähköpostiosoite
              <br />
              <br />
              Mikäli käyttää verkkosivujen chat-palvelua, ylläolevien henkilötietojen lisäksi tallennetaan:
              <br />
              <br />
              viestit
              <br />
              paikallaolotieto (online / offline)
              <br />
              IP-osoite
              <br />
              analytiikkatietoja (laitetyyppi, maa, käyttöjärjestelmä, selain, kieli, aikahyövyke, vieraillut sivut)
              <br />
              Verkkosivuston vierailijoiden IP-osoitteita ja palvelun toiminnoille välttämättömiä evästeitä käsitellään oikeutetun edun perusteella mm. tietoturvasta huolehtimiseksi ja sivuston vierailijoiden tilastotietojen keruuta varten niissä tapauksissa, kun niiden voidaan katsoa olevan henkilötietoja. Kolmansien osapuolten evästeille pyydetään tarvittaessa suostumus erikseen.
              <br />
              <br />
              6. Säännönmukaiset tietolähteet
              <br />
              Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. yhteydenoton yhteydessä ja muista tilanteista, joissa asiakas luovuttaa tietojaan.
              <br />
              <br />
              Yritysten ja muiden organisaatioiden yhteyshenkilöiden tietoja voidaan kerätä myös julkisista lähteistä kuten verkkosivuilta, hakemistopalveluista ja muilta yrityksiltä.
              <br />
              <br />
              7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle
              <br />
              Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.
              <br />
              <br />
              Chat-palvelun mahdollistamista varten tietoja käsittelee ranskalainen Crisp IM SAS käyttäjän suostumuksesta tietosuojaselosteensa mukaisesti.
              <br />
              <br />
              8. Rekisterin suojauksen periaatteet
              <br />
              Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.
              <br />
              <br />
              9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista
              <br />
              Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
              <br />
              <br />
              10. Muut henkilötietojen käsittelyyn liittyvät oikeudet
              <br />
              Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
              <br />
            </Text>
          </Flex>
        </Box>
        <Box
          px={8}
          pt={12}
          mx="auto"
        >
          <Box
            w={{
              base: 'full',
              md: 11 / 12,
              xl: 9 / 12,
            }}
            mx="auto"
            textAlign="center"
          >
            <Heading
              as="h2"
              mb={6}
              fontSize={{
                base: '3xl',
                md: '5xl',
              }}
              fontWeight="bold"
              lineHeight="none"
              letterSpacing={{
                base: 'normal',
                md: 'tight',
              }}
              color="gray.900"
              _dark={{
                color: 'gray.100',
              }}
            >
              Pulpetti-verkkotyöpöydän tietojenkäsittely
            </Heading>
          </Box>
        </Box>
        <Box>
          <Flex justifyContent="center" pt={5} pb={20}>
            <Text width={{
              base: '90vw',
              md: '80vw',
              lg: '70vw',
              xl: '40vw',
            }}
            >
              Palveluntarjoaja toimii henkilötietojen tietojenkäsittelijänä, ei rekisterinpitäjänä. Rekisterinpitäjänä toimii aina opetuksen järjestäjä.
              <br />
              <br />
              <Heading
                as="h3"
                mb={6}
                fontSize={{
                  base: '1xl',
                  md: '2xl',
                }}
                fontWeight="bold"
                lineHeight="none"
                letterSpacing={{
                  base: 'normal',
                  md: 'tight',
                }}
                color="gray.900"
                _dark={{
                  color: 'gray.100',
                }}
              >
                Kerättävät tiedot ja käyttötarkoitukset:
              </Heading>

              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Tieto</Th>
                      <th>Käyttötarkoitus</th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Koko nimi</Td>
                      <Td>MPASSid-tunnistautuminen, käyttäjän yksilöinti</Td>
                    </Tr>
                    <Tr>
                      <Td>Koulukoodi</Td>
                      <Td>MPASSid-tunnistautuminen</Td>
                    </Tr>
                    <Tr>
                      <Td>Rooli (oppilas, opettaja, ylläpitäjä…)</Td>
                      <Td>MPASSid-tunnistautuminen, järjestelmän toiminnan mahdollistaminen</Td>
                    </Tr>
                    <Tr>
                      <Td>MPASSid-tunniste</Td>
                      <Td>MPASSid-tunnistautuminen</Td>
                    </Tr>
                    <Tr>
                      <Td>Luokka</Td>
                      <Td>MPASSid-tunnistautuminen</Td>
                    </Tr>
                    <Tr>
                      <Td>Luokka-aste</Td>
                      <Td>MPASSid-tunnistautuminen</Td>
                    </Tr>
                    <Tr>
                      <Td>Oppijanumero</Td>
                      <Td>MPASSid-tunnistautuminen</Td>
                    </Tr>
                    <Tr>
                      <Td>Henkilötunnus (vain mikäli Wilma-integraatio käytössä)</Td>
                      <Td>
                        WilmaSSO-tunnistautuminen
                        <br />
                        Salataan erillisellä salausavaimella, ei mahdollista nähdä järjestelmässä
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Tunniste käyttäjärekisterissä (Puavo, M365)</Td>
                      <Td>Käyttäjän kirjaaminen Pulpetti-palveluun</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <br />
              <Heading
                as="h3"
                mb={6}
                fontSize={{
                  base: '1xl',
                  md: '2xl',
                }}
                fontWeight="bold"
                lineHeight="none"
                letterSpacing={{
                  base: 'normal',
                  md: 'tight',
                }}
                color="gray.900"
                _dark={{
                  color: 'gray.100',
                }}
              >
                Suojaus
              </Heading>

              Tiedot ovat teknisesti suojattu palomuurien sekä salauksen avulla.
              Tietoihin pääsee käsiksi vain palvelinkeskuksen
              sisäverkosta ylläpitotunnuksia käyttäen,
              eivätkä ne ole saatavilla ulkoverkosta käsin.
            </Text>
          </Flex>
        </Box>
        <Box
          sx={{
            background: 'linear-gradient(0deg, var(--chakra-colors-gray-50) 0%, #fff 100%)',
            height: '45px',
            width: '100%',
          }}
        />
      </div>
    </AppTemplate>
  );
}

export const query = graphql`
  query privacyQuery($locale: String) {
    contentfulNavigation(
      contentful_id: {eq: "39n107RjyAszi5xLee3gMS"}
      node_locale: {eq: $locale}
    ) {
      title
      navigationOptions {
        ... on ContentfulNavigationLink {
          title
          url
          __typename
        }
 
      }
      loginText
    }
  }
`;

export default Privacy;
